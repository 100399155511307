.canvas-scroll {
    position:absolute;
    top: 0;
    left: 0;
    pointer-events: none;   
}

.canvas-scroll.no-events .scrollbar-vertical, .canvas-scroll.no-events .scrollbar-horizontal {
    pointer-events: none !important;
}

.scrollbar-vertical {
    position: absolute;
    width: 32px;
    height: calc(100% - 16px);
    right: -4px;
    top: 0;
    pointer-events: all;
    background: transparent;
    user-select: none;
    z-index: 3;
}

.scrollbar-horizontal {
    position: absolute;
    width: calc(100% - 16px);
    height: 32px;
    left: 0px;
    bottom: -4px;
    pointer-events: all;
    background: transparent;
    user-select: none;
    z-index: 3;
}

.scrollbar-horizontal:hover .scroll-handle, .scrollbar-vertical:hover .scroll-handle{
    pointer-events: none;
    background: rgba(0, 0, 0, .6);
    opacity: 1 !important;
    
}

.scrollbar-horizontal .scroll-handle {
    height: 6px;
    width: 100%;
    background: rgba(0, 0, 0, .3);
    border-radius: 8px;
    position: absolute;
    left:0;
    bottom:8px;
    transition: height .5s, opacity .25s;
}

.scrollbar-vertical .scroll-handle {
    height: 100%;
    width: 6px;
    background: rgba(0, 0, 0, .3);
    border-radius: 8px;
    position: absolute;
    right:8px;
    top:0;
    transition: width .5s, opacity .25s;
}

.scrollbar-vertical:active {
    width: 25%;
}

.scrollbar-horizontal:active {
    height: 25%;
}

.scrollbar-horizontal:hover .scroll-handle { 
    height: 12px;
}

.scrollbar-vertical:hover .scroll-handle { 
    width: 12px;
}