.prompt-box {
    width: 75%;
    max-width: 640px;
    
    position: absolute;
    bottom: 8dvh;
    left: 50%;
    transform: translateX(-50%);
}

.prompt-box:hover::before {
    position: absolute;
    z-index: -1;
    content: "";
    width: calc(100% + 108px);
    height: calc(100% + 64px);
    top: -32px;
    left: -80px;
}

.prompt-box.no-events {
    pointer-events: none;
}

.prompt-box textarea {
    width: 100%;
    resize: none;
    padding: 16px 16px 12px 16px;
    font-size: 16px;
    height: 52px;
    overflow: hidden;
    background: white;
    border-radius: 24px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .25);
    
    border: 1px solid #D0D0D0;
}

.prompt-box.focus textarea {
    height: 120px;
    overflow: auto;
}

.prompt-box.focus .icon-btn, .prompt-box.generating .icon-btn {
    width: 40px;
    height: 40px;
}


.prompt-box .icon-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    padding: 4px;
}

.prompt-box .icon {
    background-color: white;
}

.magic {
    position: relative;
}

.magic .star {
    position: absolute;
}

.magic .star:nth-child(1) {
    bottom: 2px;
    left: 2px;
    width: 70%;
    height: 70%;
    
}

.magic .star:nth-child(2) {
    top: 3px;
    right: 3px;
    width: 45%;
    height: 45%;
}

.magic.loading .star:nth-child(1){
    animation: StarBlink .75s infinite;
}

.magic.loading .star:nth-child(2) {
    animation: StarBlink .75s .5s infinite;
}

@keyframes StarBlink {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.prompt-box.generating textarea {
    color: var(--gray-bg);
    filter: brightness(90%);
}

.prompt-box.generating button, .prompt-box.prompt-box.generating .dropdown {
    pointer-events: none;
}