html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, input:focus, button, textarea, textarea:focus {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; 
  box-sizing: border-box;
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, a:visited {
  text-decoration: none;
  color: inherit; }

:root {
  --bg-color: #fff;
  --bg-color-dark: #000;
  --text-color: #303030;
  --text-color-gray: #EEEEEE;
  --color-gray: #ADADAD;
  --color-gray-dark: #787878;
  --input-bg: #D9D9D9;
  --primary-color: #FF2D55;
  --secondary-color: #30B0C7;
  --gray-bg: #8E8E93;
}

* {
  user-select: none;
}

html, body, #root {
  width: 100dvw;
  height: 100dvh;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
  background: white;
  touch-action: none;
  overflow: hidden;
  -webkit-touch-callout: none !important;
}

textarea, input {
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: transparent;
}

button {
  cursor: pointer;
  background: transparent;
}


button.icon-btn {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background-color: var(--secondary-color);
}

button.icon-btn:active {
  filter: brightness(80%);
}


.icon {
  width: 100%;
  height: 100%;
  mask: center center no-repeat;
  mask-size: cover;
  flex-shrink: 0;
  background-color: white;
}








