.top-action-bar {
    position: absolute;
    top: min(2dvh, 32px);
    left: min(4dvw, 40px);
    display: flex;
    flex-direction: row;
    gap: 8px;
    z-index: 10;
    pointer-events: none;
}

.top-action-bar button {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-action-bar .icon {
    width: 56px;
    height: 56px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: transparent;
}

.top-action-bar.generating {
    opacity: .5;
    pointer-events: none;
}

.top-action-bar.no-events button {
    pointer-events: none;
}