.sketchpad {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.instructions {
    position: absolute;
    bottom: 32px;
    color: var(--gray-bg);
    font-size: 13px;
    z-index: 5;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
}