.screen {
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    position: relative;
    perspective: 2000px;
}


.bg-img {
    width: 110%;
    height: 110%;
    background: no-repeat center center;
    background-size: cover;
    position: absolute;
    top: -5%;
    left: -5%;
    filter: blur(16px);
    opacity: .9;
}

.viewport {
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 960px;
    min-width: 400px;
    min-height: 600px;
    border: 1px solid white;
    border-radius: 24px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
}


.content-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content-display {
    flex-grow: 1;
    position: relative;
}

.content-bottom {
    height: 60%;
    position: relative;
    display: flex;
    animation: ChatGrowIn 1.5s .5s;
    animation-fill-mode: both;
}

.hide-chat.content-bottom {
    animation: ChatGrowOut 1.5s;
    animation-fill-mode: both;
}

@keyframes ChatGrowIn {
    0% {
        height: 0;
    }
    100% {
        height: 60%;
    }
}

@keyframes ChatGrowOut {
    100% {
        height: 0;
    }
    0% {
        height: 60%;
    }
}

.location-error {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-gfx {
    width: 100%;
    height: 100%;
    mask: center center no-repeat cover;
    background-color: white;
    flex-shrink: 0;
}

.error-label {
    color: white;
    white-space: nowrap;
    text-align: center;
    margin-top: 8px;
    line-height: 1.4;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
}