.cover {
    width: 100%;
    height: 100%;
    background-color: #9F4C4C;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    transform-origin: center 0;
    position: absolute;
    z-index: 100;
}

.cover.open {
    animation: OpenCover 2s;
    animation-fill-mode: both;
    pointer-events: none;
}

@keyframes OpenCover {
    0% {
        transform: rotateY(0);
        box-shadow: 0 100px 100px black;
    }
    0% {
        transform: rotateY(0);
        box-shadow: 0 50px 500px black;
    }
    100% {
        transform: rotateX(90deg);
        box-shadow: 0 0 0 black;
    }
    
}

.texture-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 2;
}

.logo-block {
    background: white;
    max-width: 640px;
    min-width: 480px;
    width: 50%;
    aspect-ratio: 2/1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-block:after {
    content: "";
    position: absolute;
    left: -6px;
    top: -6px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 2px solid white;
}

.logo {
    width: 160px;
    height: 72px;
    background-repeat: no-repeat;
    background-position: center center;
}

.app-name {
    color: black;
    font-family: "Inria Sans", sans-serif;
    font-size: 48px;
    margin-top: 32px;
}

.tag-line {
    color: white;
    font-size: 32px;
    font-family: "Inria Sans", sans-serif;
    margin-top: 64px;
    line-height: 1.2;
    padding: 0 24px;
}

.text  {
    margin-top: 12px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
    color: white;
    font-family: "Inria Sans", sans-serif;
}

.cover-button {
    height: 64px;
    font-size: 24px;
    color: #258589;
    background-color: white;
    padding: 0 24px 0 32px;
    line-height: 64px;
    font-weight: bold;
    z-index: 3;
    margin-top: 20dvh;
    border-radius: 2px;
    box-shadow: 4px 4px 0 black;
    transition: box-shadow .25s;
    display: flex;
    align-items: center;
}

.cover-button .icon {
    margin-left: 8px;
    width: 32px;
    height: 32px;
    background-color: #258589;
}

.cover-button:active {
    box-shadow: 0 0 0 black;
    background-color: #D4D4D4;
}


@media only screen and (max-height: 480px) {
    .cover-button {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        padding: 0 16px 0 8px;
    }

    .cover-button span {
        display: none;
    }

    .logo-block {
        aspect-ratio: 3/1;
    }

    .app-name {
        margin-top: 8px;
    }

    .tag-line {
        margin-top: 32px;
    }
}