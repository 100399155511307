
.toolbar {
    position: absolute;
    right: min(4dvw, 40px);
    top: min(2dvh, 32px);
}

.no-events .tool-item{
    pointer-events: none;
}

.tool-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
    background-color: var(--gray-bg);
    margin: 0 0 16px 0;
    opacity: .5;
    position: relative;
    pointer-events: all;
}

.tool-item input {
    cursor: pointer;
}

.tool-item.active, .tool-item:hover {
    opacity: 1;
}

.tool-item:active {
    opacity: 1;
    filter: brightness(80%);
}



.tool-item.overflow-hidden {
    overflow: hidden;
}

.tool-item.flash {
    animation: FlashButton .5s;
}

@keyframes FlashButton {
    0% {
        background-color: var(--primary-color);
        opacity: 1;
    }
    25% {
        background-color: var(--primary-color);
        opacity: 1;
    }
    100% {
        background-color: var(--gray-bg);
        opacity: 1;
    }

}

.tool-btn {
    width: 100%;
    height: 100%;
}

.color-input {
    width: 200%;
    height: 200%;
    padding: 0;
    left: -25%;
    top: -25%;
    position: relative;
    user-select: none;
}

.brushsize-menu {
    position: absolute;
    display: flex;
    left: 0;
    transform: translate(-100%, 0);
    height: 40px;
    padding: 0 8px 0 16px;
    pointer-events: all;
}

.brushsize-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
}

.brushsize-item:hover, .brushsize-item.active {
    opacity: 1;
}

.brush-visual {
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
    margin: 8px;
}

.tool-item .hotkey-label {
    position: absolute;
    left: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    color: var(--gray-bg);
    font-size: 14px;
    margin-left: 8px;
    display: none;
}

.tool-item:hover .hotkey-label {
    display: flex;
}


.file-action-bar {
    position: absolute;
    right: min(4dvw, 40px);
    bottom: 8dvh;
    display: flex;
    flex-direction: column;
}

.file-action-bar .hotkey-label {
    font-size: 12px;
}

.file-action-bar .tool-item {
    margin: 8px 0;
}

@media only screen and (max-width: 840px) {
    .file-action-bar {
        display: flex;
        bottom: 1dvh;
        gap: 16px;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        right: 0;
    }

    .file-action-bar .hotkey-label {
        left: 50%;
        right: auto;
        top: -100%;
        transform: translate(-50%);
        white-space: nowrap;
        margin: 0;
    }
}

@media only screen and (max-width: 640px) {
    .hotkey-label {
        display: none !important;
    }
}

@media only screen and (max-height: 480px) {
    .hotkey-label {
        display: none !important;
    }
}