.canvas-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: white;
}

.canvas-wrapper.generating .canvas-layer {
    filter: blur(32px);
    animation: Generating 3s 1s infinite;
}

@keyframes Generating {
    0% {
        filter: blur(32px);
    }
    50% {
        filter: blur(16px);
    }   
    100% {
        filter: blur(32px);
    }
}

.canvas-wrapper.generating .canvas-base {
    pointer-events: none;
    cursor:not-allowed !important;
}

.canvas-base {
    touch-action: none;
}

.canvas-base.grab {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.canvas-base.grabbing {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.canvas-layer {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    pointer-events: none;
    transition: filter 1s;
}

#segments.canvas-layer {
    opacity: 0.8;
}