
.instructions {
    position: absolute;
    bottom: 2dvh;
    color: var(--gray-bg);
    font-size: 13px;
    z-index: 5;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    animation: ShowInstruction .5s;
}

.instructions {
    text-align: center;
    user-select: none;
    line-height: 1.2;
}

.instructions.error {
    color: red;
    display: block !important;
}

@media only screen and (max-width: 640px) {
    .instructions {
        display: none;
        bottom: auto;
        top: 4dvh;
    }
}

@keyframes ShowInstruction {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
    } 100% {
        opacity: 100%;
        transform: translateX(-50%) translateY(0);
    }
}