.sketchbook {
    width: 100%;
    height: 100%;
    position: relative;
}

.right-panel {
    position: absolute;
    right: 0;
    height: 100%;
    pointer-events: none;
    overflow-y: auto;
    top: 0;
    z-index: 5;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

@media only screen and (max-height: 360px) {
    .right-panel {
        pointer-events: all;
        align-items: end;
        padding: 16px 16px 12px 8px;
        width: auto;
    }
    .file-action-bar, .toolbar {
        position: relative !important;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }

    .file-action-bar {
        padding-top: 4px;
        border-top: 1px solid rgba(0, 0, 0, .2);
    }

    .tool-item {
        position: initial !important;
    }

    .brushsize-menu {
        position: fixed !important;
        top: 16px !important;
        right: 76px !important;
        left: auto !important;
        transform: none !important;
        z-index: 1000;
    }
}