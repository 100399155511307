.action-bar {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: none;
    top: 50%;
    left: min(4dvw, 40px);
    transform: translateY(-50%);
    z-index: 10;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .25);
}

.action-bar.show {
    display: flex;
}

.action-bar.no-events button {
    pointer-events: none;
}

.action-bar button {
    pointer-events: all;
    background-color: var(--gray-bg);
    color: white;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    font-size: 13px;
    opacity: .5;
    position: relative;
}

.action-bar button:nth-child(1) {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.action-bar button:nth-child(2) {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.action-bar button:hover {
    opacity: 1;
}

.action-bar button:active {
    opacity: 1;
    filter: brightness(80%);
}

.action-bar button.flash {
    animation: FlashButton .5s;
}

@keyframes FlashButton {
    0% {
        background-color: var(--primary-color);
        opacity: 1;
    }
    25% {
        background-color: var(--primary-color);
        opacity: 1;
    }
    100% {
        background-color: var(--gray-bg);
        opacity: 1;
    }

}

.action-btn .hotkey-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    top: -100%;
    display: flex;
    align-items: center;
    color: var(--gray-bg);
    font-size: 12px;
    display: none;
    white-space: nowrap;
    pointer-events: none;
}

.action-btn:nth-child(2) .hotkey-label {
    top: auto;
    bottom: -100%;
}

.action-btn:hover .hotkey-label {
    display: flex;
}

.action-bar.generating {
    opacity: .5;
}

.action-bar.generating .action-btn {
    pointer-events: none;
}