.dropdown {
    position: absolute;
    left: 50%;
    bottom: 4px;
    transform: translate(-50%);
    height: 24px;
    border-radius: 24px;
    padding: 0 24px 0 12px;
    line-height: 24px;
    font-size: 12px;
    color: var(--gray-bg);
    display: none;
}

.focus .dropdown {
    display: block;
}

.dropdown:hover {
    background-color: #f4f4f4;
}

.dropdown select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.dropdown .tick {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 8px;
    height: 8px;
    transform: rotate(-135deg) scale(.75);
}

.tick:after, .tick::before {
    content:"";
    height: 100%;
    width: 2px;
    background-color: var(--color-gray);
    position: absolute; 
}

.tick:after {
    width: 100%;
    height: 2px;
}