* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }

.print-wrapper {
    width: 100dvw;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.print-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.no-print {
  position: absolute;
  bottom: 4dvh;
  left: 50%;
  transform: translateX(-50%);
}

.no-print button {
  pointer-events: all;
    background-color: var(--gray-bg);
    color: white;
    border-radius: 6px;
    padding: 0 8px;
    margin: 0;
    width: auto;
    height: 40px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    font-size: 13px;
    opacity: .5;
    position: relative;
}

.no-print button:hover {
  opacity: 1;
}

.no-print button:active {
  opacity: 1;
  filter: brightness(80%);
}
